import styles from "styles/Home.module.scss";
import { useChain } from "react-moralis";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { useAddToCart } from "../utils/cart";
import { useTranslation } from "react-i18next";
import {
  NotificationType,
  showNotification,
} from "../context/notifications/actions";
import { NotificationContext } from "../context/notifications/context";
import { useRouter } from "next/router";
import {
  MARKET_COLLECTION_URL_REGEX,
  MARKET_URL_REGEX,
} from "../utils/constants";
import Spacer from "./Spacer";
import Image from "next/image";
import { getChainNameByKey } from "../utils/networks";
import { Header, HeaderVariant } from "./Header";
import { GetStarted } from "./GetStarted";
import { Meta } from "./Meta";

interface Props {}

const Home: React.FC<Props> = ({}) => {
  const [url, setUrl] = React.useState("");
  const { t } = useTranslation();
  const notification = React.useContext(NotificationContext);
  const { chainId, switchNetwork } = useChain();

  const isDiffChainId = useMemo(() => {
    if (chainId == null) return false;

    return chainId !== process.env.NEXT_PUBLIC_CHAIN_ID;
  }, [chainId]);

  const [focusing, setFocusing] = useState(false);

  const addToCart = useAddToCart();

  const router = useRouter();
  useEffect(() => {
    const tokenId = router.query.tokenId as string;
    const contractAddress = router.query.contractAddress as string;
    if (tokenId && contractAddress) {
      addToCart({ tokenId, contractAddress }).then();

      router.replace("/").then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const onAddUrl = useCallback(
    async (url = "") => {
      setUrl(url);

      // get data from open sea, looksrare and coinbase
      const regEx = new RegExp(MARKET_URL_REGEX);
      if (!regEx.test(url)) {
        const collectionRegEx = new RegExp(MARKET_COLLECTION_URL_REGEX);
        if (collectionRegEx.test(url)) {
          notification.dispatch(
            showNotification({
              text: t("error.invalidNFTUrlCollection"),
              type: NotificationType.error,
            })
          );

          setTimeout(() => setUrl(""), 450);

          return;
        }

        notification.dispatch(
          showNotification({
            text: t("error.invalidNFTUrl"),
            type: NotificationType.error,
          })
        );

        return;
      }

      // blur active input
      (document.activeElement as HTMLInputElement)?.blur();

      const params = url.split("?")[0].split("/");
      const contractAddress = params[params.length - 2];
      const tokenId = params[params.length - 1];

      await addToCart({ tokenId, contractAddress, updateUrl: setUrl });
    },
    [addToCart, notification, t]
  );

  const onKeyUp = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && url.trim().length > 0) {
        await onAddUrl(url.trim());
      }
    },
    [onAddUrl, url]
  );

  const [showGetStartedModal, setShowGetStartedModal] = useState(false);

  return (
    <div className={styles.container}>
      <Meta />

      <main className={"column ai-center"}>
        <Header variant={HeaderVariant.light} />
        <div className={clsx(styles.main, "column ai-center")}>
          <Spacer flex={0.4} />
          {isDiffChainId && (
            <>
              <div className={styles.title}>{t("tagline")}</div>
              <Spacer flex={1} />
              <div className={"column ai-center jc-center"}>
                <div className={"column ai-center"}>
                  <Image
                    priority
                    src={"/warning.svg"}
                    width={50}
                    height={50}
                    alt={"warning icon"}
                  />
                  <Spacer height={24} />
                  <div className={styles.pleaseUseComputer}>
                    {t("wrongNetwork", {
                      chainName: getChainNameByKey(
                        process.env.NEXT_PUBLIC_CHAIN_ID as string
                      ),
                    })}
                  </div>
                  <Spacer height={18} />
                  <div
                    className={styles.seeHowItWorks}
                    onClick={() =>
                      switchNetwork(process.env.NEXT_PUBLIC_CHAIN_ID!)
                    }
                  >
                    {t("switchNetwork")}
                  </div>
                </div>
              </div>
              <Spacer flex={1} />
            </>
          )}
          {!isDiffChainId && (
            <div className={"column ai-center jc-center"}>
              <div className={styles.title}>{t("tagline")}</div>
              <Spacer height={40} />
              <div
                className={clsx("row ai-center", styles.inputContainer, {
                  [styles.inputFocusingContainer]: focusing,
                })}
              >
                <Image
                  priority
                  src={"/search.svg"}
                  width={18}
                  height={18}
                  alt={"search"}
                  layout={"fixed"}
                  className={clsx(styles.searchIcon, {
                    [styles.focusing]: focusing,
                  })}
                />
                <input
                  type={"url"}
                  value={url}
                  className={styles.input}
                  placeholder={t("enterUrl")}
                  onFocus={() => setFocusing(true)}
                  onBlur={() => setFocusing(false)}
                  onChange={({ target: { value } }) => onAddUrl(value.trim())}
                  onKeyUp={onKeyUp}
                  data-testid={"input-url"}
                />
              </div>
              <Spacer height={28} className={"hideOnTablet"} />
              <Spacer height={24} className={"hideOnDesktop"} />
              <div
                className={"row ai-center"}
                onClick={() => setShowGetStartedModal(true)}
              >
                <div
                  className={clsx(
                    "row ai-center cursor-pointer",
                    styles.menuButton,
                    styles.text
                  )}
                >
                  {t("home.howToGetStarted")}
                  <Spacer width={8} />
                  <Image
                    src={"/long-arrow.svg"}
                    priority
                    width={24}
                    height={24}
                    alt={"arrow icon"}
                    layout={"fixed"}
                  />
                </div>
              </div>
            </div>
          )}
          <Spacer flex={0.6} />
        </div>
        {showGetStartedModal && (
          <GetStarted onClose={() => setShowGetStartedModal(false)} />
        )}
      </main>
    </div>
  );
};

export default Home;
